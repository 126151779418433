import React, { useState } from "react";
import {
  InputAdornment,
  IconButton,
  FormControlLabel,
  Grid,
  Stack,
  Box,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Footer from "../components/Footer";
import {
  StyledLoginDashboard,
  StyledLoginContainer,
  StyleTextBox,
  StyleFieldsContainer,
  StyledLogo,
  StyledSubmitButton,
  StyledCheckbox,
  StyledTextWhite,
} from "../styles/Login";
import Link from "../components/LinkText";
import { login } from "../api/auth";

const LOGIN_ERROR =
  "The user name of password that you have entered does not match our records. Please review your username and password and try again.";

export default function LoginDashboard() {
  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleNameChange = (event) => {
    setLoginForm({ ...loginForm, email: event.target.value });
  };

  const handlePasswordChange = (event) => {
    setLoginForm({ ...loginForm, password: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    login({ ...loginForm })
      .then(() => {
        let loginDestination = "/";
        window.location.href = loginDestination;
      })
      .catch((err) => {
        console.log("error", err);
        alert(LOGIN_ERROR);
      });
  };

  return (
    <Box>
      <StyledLoginDashboard container>
        <Grid item xs={1.5} sm={2} md={3.5} lg={4} />
        <Grid item xs={9.5} sm={8} md={5} lg={4}>
          <StyledLoginContainer spacing={2}>
            <StyledLogo src="/ValorRPM_Logo-color_gradient.svg" alt="test" />
            <StyleFieldsContainer spacing={2}>
              <StyleTextBox
                name={"Email"}
                placeholder={"Email"}
                value={loginForm.email.toLowerCase()}
                onChange={handleNameChange}
              ></StyleTextBox>
              <StyleTextBox
                name={"Password"}
                placeholder={"Password"}
                type={showPassword ? "text" : "password"}
                value={loginForm.password}
                onChange={handlePasswordChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label={"toggle password visibility"}
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              ></StyleTextBox>
              <Stack direction={"row"} spacing={10}>
                <Link
                  component="button"
                  text={"Forgot your password?"}
                  onClick={() => {
                    console.log("forgot");
                  }}
                  type={"white"}
                />
                <FormControlLabel
                  control={<StyledCheckbox />}
                  label={<StyledTextWhite>Remember me</StyledTextWhite>}
                />
              </Stack>
              <StyledSubmitButton color="success" onClick={handleSubmit}>
                {"Submit"}
              </StyledSubmitButton>
            </StyleFieldsContainer>
          </StyledLoginContainer>
          <Footer />
        </Grid>
        <Grid item xs={1} sm={2} md={3.5} lg={4} />
      </StyledLoginDashboard>
    </Box>
  );
}
