import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Arial, Helvetica, sans-serif",
  },
  size: {
    screen: window.innerHeight,
  },
  palette: {
    button: {
      disabled: "#C8EAC8",
      green: "#28B726",
    },
    checkbox: {
      main: "lightGrey",
    },
    background: {
      lightGrey: "#E6E6E6",
      lightBlue: "#E1F5FE",
      darkBlue: "#01579B",
    },
    text: {
      black: "#1A1A1A",
      lightGrey: "#E6E6E6",
      grey: "#7B7B7B",
      link: "#2179D2",
      white: "#FFFFFF",
    },
    border: {
      black: "#000000",
    },
  },
});

export default theme;
