export default function NavBar() {
  return (
    <div>
      <div>Open menu icon</div>
      <div>Patients</div>
      <div>Coordinators</div>
      <div>Analytics</div>
      <div>Preferences</div>
      <div>Settings</div>
    </div>
  );
}
