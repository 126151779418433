import { styled } from "@mui/material/styles";
import {
  Checkbox,
  TextField,
  Button,
  Stack,
  Grid,
  Paper,
  Typography,
  BottomNavigationAction,
} from "@mui/material";

export const StyledTextWhite = styled(Typography)(({ theme }) => ({
  color: theme.palette.background.lightBlue,
}));

export const StyledLoginDashboard = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.background.lightBlue,
  paddingTop: theme.spacing(2),
  position: "fixed",
  bottom: theme.spacing(0),
  top: theme.spacing(0),
}));

export const StyledFooterContainer = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.lightBlue,
}));

export const StyledLoginContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.background.darkBlue,
  borderRadius: theme.spacing(6),
  padding: theme.spacing(2),
}));

export const StyleFieldsContainer = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(1),
}));

export const StyleTextBox = styled(TextField)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  color: theme.palette.text.lightGrey,
  backgroundColor: theme.palette.background.lightGrey,
  padding: theme.spacing(0),
  margin: theme.spacing(0),
}));

export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.checkbox.main,
}));

export const StyledLogo = styled("img")(({ theme }) => ({
  border: `solid 2px ${theme.palette.border.black}`,
  borderRadius: theme.spacing(6),
  background: theme.palette.background.lightBlue,
  width: "100%",
}));

export const StyledSubmitButton = styled(Button)(({ theme }) => ({
  background: theme.palette.button.green,
  color: theme.palette.text.white,
}));

export const StyledBottomNavigationAction = styled(BottomNavigationAction)(
  () => ({
    textDecoration: "underline",
  })
);
