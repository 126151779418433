import { RPM_API_URL } from "../globalVariables";
import { encrypt } from "../utilities/session";

//TODO: connect token expiration to backend api
let TOKEN_EXPIRATION = 2592000;

export const login = async (props) => {
  const data = {
    username: props.email,
    password: props.password,
  };
  const response = await fetch(`${RPM_API_URL}/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify(data),
  });
  return await response
    .json()
    .then((access) => {
      const expiration = new Date();
      expiration.setTime(expiration.getTime() + TOKEN_EXPIRATION);
      let expires = expiration.toUTCString();
      const cookieBody = {
        token: access.token,
        expires: expires,
      };
      document.cookie = `life365_rpm_session=${encrypt(
        JSON.stringify(cookieBody)
      )}`;
      return access.token;
    })
    .catch((err) => {
      throw err;
    });
};
