import { ThemeProvider } from "@mui/material/styles";
import { Routes, Route } from "react-router-dom";
import theme from "./styles/theme.syles.jsx";
import Login from "./dashboards/Login.jsx";
import Main from "./dashboards/Main.jsx";

function App() {
  const checkSession = (component) => {
    let decodedCookie = decodeURIComponent(document.cookie);
    const coordinatorCookie = decodedCookie.split("; ").find((cookie) => {
      return cookie.split("=")[0] === "life365_rpm_session";
    });
    if (coordinatorCookie?.split("=")[0] === "life365_rpm_session") {
      return component;
    } else {
      return <Login />;
    }
  };
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={checkSession(<Main />)} />
        </Routes>
      </ThemeProvider>
    </div>
  );
}

export default App;
