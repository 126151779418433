export default function HeaderBar() {
  return (
    <div>
      <div>VSIN Hero</div>
      <div>Search Bar</div>
      <div>Valor Hero</div>
      <div>Avatar</div>
    </div>
  );
}
