import React from "react";
import { BottomNavigation, Paper } from "@mui/material";
import { StyledBottomNavigationAction } from "../styles/Login";

export default function Footer() {
  const [value, setValue] = React.useState(0);
  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
      }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <StyledBottomNavigationAction label="Terms" />
        <StyledBottomNavigationAction label="Privacy" />
        <StyledBottomNavigationAction label="Contact" />
      </BottomNavigation>
    </Paper>
  );
}
