import { styled } from "@mui/material/styles";
const typeSelect = (type, theme) => {
  switch (type) {
    case "white":
      return theme.palette.text.white;
    case "blue":
      return theme.palette.text.link;
    default:
      return theme.palette.text.link;
  }
};
export default function Link({ text, onClick, type }) {
  const StyledLink = styled("p")(({ theme }) => ({
    color: typeSelect(type, theme),
    cursor: "pointer",
  }));
  return <StyledLink onClick={onClick}>{text}</StyledLink>;
}
