import HeaderBar from "../components/HeaderBar";
import NavBar from "../components/NavBar";
import DataGrid from "../components/DataGrid";
import SideGridBar from "../components/SideGridBar";
import { StyledMainDashboard } from "../styles/Main";
import Footer from "../components/Footer";
import { useEffect } from "react";
import { callAPI } from "../utilities/universal";

export default function MainDashboard() {
  useEffect(() => {}, []);
  return (
    <StyledMainDashboard>
      <NavBar />
      <div>
        <HeaderBar />
        <div>
          <DataGrid />
          <SideGridBar />
        </div>
      </div>
      <Footer />
    </StyledMainDashboard>
  );
}
