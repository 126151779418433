export default function SideGridBar() {
  return (
    <div>
      <div>Good Morning Patient</div>
      <div>Severe patient warnings tile</div>
      <div>Moderate patient warnings tile</div>
      <div>normal patients tile</div>
      <div>
        <div>Pending enrolment</div>
        <div>Paused</div>
      </div>
    </div>
  );
}
